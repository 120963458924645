import { ThemeProvider, type ThemeType } from '@hiyllo/ux/theme';
import React from 'react';

const HiylloTokyoThemeDark: ThemeType = {
  buttonForeground: 'white',
  buttonBackground: 'linear-gradient(to right, rgb(254 2 254), rgb(254 143 1))',
  background1: '#101014',
  background2: '#17161B',
  background3: '#201f24',
  midground: '#37363a',
  foreground: '#ffffff',
  foregroundInactive: '#656570',
  surfaceShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 20px 0px',
  colorScheme: 'dark'
};

export const UXThemeProvider = React.memo(function UXThemeProvider (
  props: React.PropsWithChildren
): JSX.Element {
  return <ThemeProvider theme={HiylloTokyoThemeDark}>{props.children}</ThemeProvider>;
});
