import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './index.css';
import { UXThemeProvider } from './providers/ux-theme-provider';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <UXThemeProvider>
    <App />
  </UXThemeProvider>
  // </React.StrictMode>
);
