/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = null;
export type ResponseType = { href: string; };
export const Endpoint = 'auth/request-login';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};

export const isPublic = true;export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}