/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { StardateLogKind } from "@hiyllo/stardate/main";
import { StoredLogItem } from "../../types/log";


export type ParamsType = ({
  before: Date;
} | {
  after: Date;
}) & {
  filters?: {
    kind?: StardateLogKind[];
    userId?: string;
    tenant?: string;
    message?: string;
    platform?: string;
  } | null
};
export type ResponseType = {
  logs: StoredLogItem[];
};
export const Endpoint = 'logs/retrieve-logs';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"anyOf":[{"allOf":[{"type":"object","properties":{"before":{"type":"object","format":"date-time"}},"required":["before"]},{"type":"object","properties":{"filters":{"anyOf":[{"type":"object","properties":{"kind":{"type":"array","items":{"enum":["error","info","latency","trace","warning"],"type":"string"}},"userId":{"type":"string"},"tenant":{"type":"string"},"message":{"type":"string"},"platform":{"type":"string"}}},{"type":"null"}]}}}]},{"allOf":[{"type":"object","properties":{"after":{"type":"object","format":"date-time"}},"required":["after"]},{"type":"object","properties":{"filters":{"anyOf":[{"type":"object","properties":{"kind":{"type":"array","items":{"enum":["error","info","latency","trace","warning"],"type":"string"}},"userId":{"type":"string"},"tenant":{"type":"string"},"message":{"type":"string"},"platform":{"type":"string"}}},{"type":"null"}]}}}]}],"$schema":"http://json-schema.org/draft-07/schema#"}